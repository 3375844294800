import AliceCarousel from 'react-alice-carousel';

import "react-alice-carousel/lib/alice-carousel.css";

import React,{useState,useEffect} from 'react';
import African from '../../Images/african.png';
import Sidebar from '../../components/sidebar/sidebar';
import server from '../../server/server';


const favourites =[
    {
        id:0,
        image:African,
        name:'Virk'
    },
    {
        id:1,
        image:African,
        name:'Shehroz'
    },
    {
        id:2,
        image:African,
        name:'Sherry'
    },
    {
        id:3,
        image:African,
        name:'Black'
    }
]

const Favourite = () =>{
    const [userFav, setUserFav] = useState([]);


    const getUserProfile = async()=>{
        const userId = localStorage.getItem('userId');
        const data={
            userId:userId
        }
        const response = await server.post('user/showFav',data)
        // console.log(response.data.favourites[0].userId);
        setUserFav(response?.data?.favourites)
    }
    useEffect(()=>{
        getUserProfile();
    
    },[])
    




    return(
        <>
        <div className='profile-container'>
            <Sidebar/>
        <div className='background'>
                <div className='container'>

                    <div className='borderBottom'>
                        <h1 className='text-black fw-light p-4'>
                            My Favourites
                        </h1>
                    </div>
                    <div className='favourites my-4'>
                    <div className='row d-flex'>
                        
                        {userFav?.map((data)=>(
                            <div className='col col-2'>
                            <img src={data?.userId?.profileImage} style={{
                                width:120,height:120,marginInline:25
                            }} alt='Profile'/>
                            <p className='text-black text-center fw-bold mt-4'>{data?.userId?.firstName}</p>
                            </div>
                        ))}
                        </div>                

                    </div>
                </div>

            </div>
        
        </div>
        </>
    )
}


export default Favourite;