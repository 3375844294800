import React from 'react'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import NavigationBar from '../../components/navbar/navbar'
import './HomeScreen.css'
import { Link } from 'react-router-dom'
import Garage from '../../Images/background.jpg'
import BMG from '../../Images/bmgLogo.png'
import NumberPlate from '../../Images/registeration.png'
import 'react-alice-carousel/lib/alice-carousel.css'
import AliceCarousel from 'react-alice-carousel'
import Footer from '../../components/footer/Footer'
import Garage1 from '../../Images/Garage1.jpg'
import Garage2 from '../../Images/Garage4.jpg'
import Garage3 from '../../Images/Garage3.jpg'
import Garage5 from '../../Images/Garage5.jpeg'
import blogs from '../../Images/blogs.png'
import blog_1 from '../../Images/blog1.jpeg'
import blog_2 from '../../Images/blog2.jpeg'
import blog_3 from '../../Images/blog3.jpeg'
import blogs1 from '../../Images/icons/About us.jpg'
import '../../../node_modules/video-react/dist/video-react.css'
import { useNavigate } from 'react-router-dom'
import VideoBg from '../../Images/video.mp4'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import LOGO from '../../Images/friendsRightNowLogo.png'
import WhiteLogo from '../../Images/image.png'
import { Player } from 'video-react'
const handleDragStart = (e) => e.preventDefault()

const HomeScreen = () => {
  const navigate = useNavigate()

  const getQuotes = () => {
    navigate('/signup')
  }

  const moveToLogin = () => {
    navigate('/login')
  }
  return (
    <div className='container-fluid'>
      {/* <div className=''>
                <video src={VideoBg} className='video' autoPlay loop muted />
                    <div className='content d-flex row justify-content-evenly my-2'>
                        <img src={WhiteLogo} style={{ width: 250, height: 200, marginLeft: 40 }} alt="FriendsRightNow"/>
                        <button type='submit' style={{ backgroundColor: 'transparent', color: 'white', fontSize: '20px', border: '1px solid white', height: '50px', borderRadius: 10, width: '200px', fontWeight: 'lighter', marginTop: 15 }} onClick={moveToLogin}>Login now</button>
                    </div>
                    <div className='content d-flex row'>
                        <h1 className='hero-heading'>
                            Expand your Social<br /> Circle Virtually
                        </h1>
                        <p className='text-white'>Wherever you are at this moment, Invite A New Friend Into Your Life</p>
                        <div>
                            <button type='button' className='joinusnowBtn' onClick={getQuotes} >Join us Now</button>
                        </div>
                    </div>
            </div> */}
      <div className='video-background'>
        <video autoPlay muted loop>
          <source src={VideoBg} type='video/mp4' />
          Your browser does not support the video tag.
        </video>
        <div className='content '>
          <div className='d-flex justify-content-between'>
            <img
              src={WhiteLogo}
              style={{ width: 250, height: 200, marginLeft: 40 }}
              alt='FriendsRightNow'
            />
            <button className='button' onClick={moveToLogin}>
              Login
            </button>
          </div>
          <div className='d-flex row'>
            <h1 className='hero-heading'>
              Expand your Social
              <br /> Circle Virtually
            </h1>
            <p className='text-white'>
              Wherever you are at this moment, Invite A New Friend Into Your
              Life
            </p>
            <div>
              <button
                type='button'
                className='joinusnowBtn'
                onClick={getQuotes}
              >
                Join us Now
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='container my-4'>
        <div className='row align-items-center'>
          <div className='col col-1'></div>
          <div className='col col-4 my-4 hardCoded'>
            <h5 className='text-black fw-normal text-center '>
              How are we Different than Other Friendship Sites? Why is this the
              Friendship Site For You?
            </h5>
            <p className='text-black fw-light text-center py-4'>
              If you need a friend to talk to right now, you should have one.
              You deserve a space that is safe and fun to meet real friends. A
              friendship site that took time to create accountability for its
              members to ensure they have true intentions of being a friend.
              Friends Right Now is your place to for good, honest, kind,
              supportive, diverse, caring, genuine, interesting and fun
              friendships. We foster a sense of community for anyone wanting
              friends.
            </p>
            <button className='learnMore' onClick={getQuotes}>
              Signup
            </button>
          </div>
          <div className='col col-1'></div>
          <div className='col col-3'>
            <img
              src={require('../../Images/people.png')}
              style={{
                width: 500,
              }}
            />
          </div>
        </div>
      </div>

      <div className='container my-4'>
        <div className='row align-items-center'>
          <div className='col col-4'>
            <img
              src={require('../../Images/girlLaptop.png')}
              style={{
                width: 500,
              }}
            />
          </div>
          <div className='col col-2'></div>
          <div className='col col-4'>
            <h4 className='text-black fw-normal text-center py-4'>
              How can we promise you’ll meet good friends here?
            </h4>
            <p className='text-black fw-light text-center'>
              Your potential friend took time to create a profile on this
              website and is not a random stranger. Your potential friend
              explained on their profile why they will be a good friend and what
              they have to offer as a friend. They have also paid a $2.00 a
              month pledge: $1.00 to be a good friend. $1.00 to expect good
              friendships. The profile and pledge holds your potential friend
              accountable. Chat is only allowed after a profile and pledge has
              been made.{' '}
            </p>
            {/* <button className='learnMore'>Signup</button> */}
          </div>
        </div>
      </div>

      <div className='container my-4'>
        <div className='row align-items-center'>
          <div className='col col-1'></div>
          <div className='col col-4'>
            <h4 className='text-black fw-normal text-center'>
              Why Do I Have To Pay $2.00 When Other Websites Are Free?{' '}
            </h4>
            <p className='text-black fw-light text-center py-4'>
              Friendship websites that are free don’t have the best interest of
              the users, accountability, or sense of community. They make
              massive profit by having advertising displayed on their website.
              By paying $2.00 per month, you are keeping this website
              advertisement free- thus creating a seamless friendship connecting
              experience, without advertising, helping with the platform
              continue, and agree to accountability and community. We have
              genuine intent for you to find good friends.{' '}
            </p>
            {/* <button className='learnMore'>Learn More</button> */}
          </div>
          <div className='col col-1'></div>
          <div className='col col-3'>
            <img
              src={require('../../Images/girlGreen.png')}
              style={{
                width: 500,
              }}
            />
          </div>
        </div>
      </div>

      <div className='container my-4'>
        <div className='row align-items-center'>
          <div className='col col-4'>
            <img
              src={require('../../Images/group.png')}
              style={{
                width: 500,
              }}
            />
          </div>
          <div className='col col-2'></div>
          <div className='col col-4'>
            <h4 className='text-black fw-normal text-center'>
              How Do I Use This Website to Find and Talk To Friends?{' '}
            </h4>
            <p className='text-black fw-light text-center py-4'>
              You Can Find Friends In Two Ways: Profile Search or Video Chat.
              <br />
              You can browse through profiles to find friends to chat with. Or,
              the unique feature, go straight to chat. For Both Profile Search
              and Video Chat Options: You can filter your friends by Country,
              Language, College/University, Age, Gender, Interests, Hobbies,
              Supportive, or any Keywords you find important.
              <br />
              <br />
              Profile Search: With the Profile Search, you have the option to
              text your potential friend first before video chatting. Overtime,
              as you meet friends, you can add them to your favourites page.
              Eventually, you can video chat with your friends and not just text
              them.
              <br />
              <br />
              Video Chat: The unique feature is go straight to Video Chat. You
              do a specific chat by using your keyword filters... Or, a random
              chat if you are open to meeting a unique friend that you otherwise
              might not have a chance to. This could be locally or globally. Of
              course, they have to be online the same time as you{' '}
            </p>
            {/* <button className='learnMore'>Learn More</button> */}
          </div>
        </div>
      </div>

      {/* <div className='container'>
                <div className='row align-items-center'>
                    <div className='col col-1'></div>
                    <div className='col col-4 hardCoded'>
                        <h4 className='text-black fw-normal text-center'>
                        Get Student Discount                        </h4>
                        <p className='text-black fw-light text-center py-4'>
                        In this category we can say that if you didn’t make any friends your first year, as in you you didn’t add anyone and they didn’t add you mutually, we will give you 6 months free on the website. College Students Pay $1.00 a month for their first year. ID will need to be e-mailed for verification. This is to ensure safety and that you are really a college student.                        </p>
                        <button className='learnMore' onClick={getQuotes}>Signup</button>
                    </div>
                    <div className='col col-1'></div>
                    <div className='col col-3'>
                        <img src={require('../../Images/student.png')} style={{
                            width: 500
                        }} />
                    </div>
                </div>
            </div> */}

      <Footer />
    </div>
  )
}

export default HomeScreen
