import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { storage, auth } from '../../components/firebase/firebase';
import './RegisterationScreen.css';

const Register = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [fullName, setFullName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [age, setAge] = useState('');
    const [country, setCountry] = useState('');
    const [gender, setGender] = useState('');
    const [language, setLanguage] = useState('');
    const [selectedFiles, setFiles] = useState("");
    const [url, setURL] = useState("");


    const [file, setFile] = useState("");
    const [Title, setTitle] = useState("");
    const [Price, setPrice] = useState("");
    const [Description, setDescription] = useState("");
    const showFile = (event) => {
        let file = event.target.files[0];
        setFiles(file);
    };

    const Backend = async (values) => {
        // const res = await server.post("products/addproduct", values);
        // if (res.status === 200) {
        // alert("Added protuct")
        // history.push("/allproducts");
        // window.location.reload();
        // }

    }
    const onSubmit = async () => {
        auth.signInAnonymously()
            .then(() => {
                // Signed in..
                // const data = values
                const uploadTask = storage.ref(`/images/${selectedFiles.name}`).put(selectedFiles);
                uploadTask.on("state_changed", console.log, console.error, () => {
                    storage
                        .ref("images")
                        .child(selectedFiles.name)
                        .getDownloadURL()
                        .then((url) => {
                            setFiles(null);
                            setURL(url);
                            // alert(url);

                            // Backend(values, url);
                            // console.log(data);
                            // const data = new FormData();



                            // for (var propName in values) {
                            //   //to remove any empty field
                            //   if (values[propName] === "") {
                            //     delete values[propName];
                            //   }
                            // }
                            // values.append("obj", JSON.stringify({ ...values, image: url }));
                            // const img =  FileResize(selectedFiles);
                            // data.append("image", img);
                            // console.log("aur bhaui")

                            // values['imageUrl']=url,

                            // alert(JSON.stringify(values))


                            // console.log(values);
                            // val['imageUrl'] = url
                            register(url);

                            // dispatch(createProduct(values, url, setLoading));

                            // resetForm({
                            //   values: "",
                            // });
                            // history.push("/productList");
                            setFiles(null);
                        })
                })


            })
    }
    const register = async (url) => {
        // const data = {
        //   email: email,
        //   password: password
        // }
        // const response = await server.post('/user/login', data);
        // if (response.status === 200) {
        //   localStorage.setItem('userId', response.data._id);
        //   navigate('/userDashboard');
        // }
        console.log(url);
        navigate('/registerDetails', { state: { firstName: location.state.firstName, lastName: location.state.lastName, email: location.state.email, password: location.state.password, age: age, gender: gender, language: language, country: country, phoneNumber: phoneNumber, imageUrl: url } });
    }

    return (
        <>
            <div className='background'>
                <div className='container'>

                    <div className='borderBottom'>
                        <h1 className='text-black fw-light p-4'>
                            Register Now
                        </h1>
                    </div>
                    <div className="">
                  
                            <input
                                type="file"
                                name="file"
                                onChange={(e) => showFile(e)}
                                id="file"
                                className="bl-inputfile"
                            />
                        
                         
                        {/* <button

                            // type="button"
                            className="px-3 py-2"
                            style={{
                                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                width: "120px",
                                borderRadius: "5px",
                                border: "1px solid #244e78",
                                background: "#244e78",
                                color: "white",
                                fontWeight: "bold",
                                fontSize: "12px",
                            }}
                            onClick={onSubmit}
                        // onSubmit={handleSubmit}
                        >
                            Save Changes
                        </button> */}
                    </div>

                    <div className=' d-flex justify-content-between my-4'>
                        <input type='register' placeholder='Full Name' onChange={(e) => { setFullName(e.target.value) }} />
                        <input type='register' placeholder='Age' onChange={(e) => { setAge(e.target.value) }} />
                        <input type='register' placeholder='Language' onChange={(e) => { setLanguage(e.target.value) }} />
                    </div>


                    <div className=' d-flex justify-content-between my-4'>
                        <input type='register' placeholder='Country' onChange={(e) => { setCountry(e.target.value) }} />
                        <input type='register' placeholder='Gender' onChange={(e) => { setGender(e.target.value) }} />
                        <input type='register' placeholder='Phone Number' onChange={(e) => { setPhoneNumber(e.target.value) }} />
                    </div>

                    <button className='nextButton' onClick={onSubmit}>Next</button>

                </div>

            </div>
        </>
    )
}

export default Register;