import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { SidebarData } from "./sidebarData.js";
import { useNavigate } from "react-router-dom";
import SubMenu from "./subMenu.js";
// import { IconContext } from "react-icons/lib";
import './sidebar.css';

const Nav = styled.div`
  background: #15171c;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
 
const NavIcon = styled(Link)`
  margin-left: 2rem;
  font-size: 2rem;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
 
const SidebarNav = styled.nav`
  background: #768E94;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  transition: 350ms;
  z-index: 10;
`;
 
const SidebarWrap = styled.div`
  width: 100%;
`;
 
const Sidebar = () => {
  const navigate = useNavigate();
 
  const logout = async()=>{
    localStorage.removeItem('userId');
    navigate('/login')
  }


  return (
    <>
        <div className="sidebarBackground">
          <SidebarWrap>
            {SidebarData.map((item, index) => {
              return <SubMenu item={item} key={index} />;
            })}
          </SidebarWrap>
          <button className="logoutBtn" onClick={logout}>Logout</button>
        </div>
    </>
  );
};
 
export default Sidebar;