// FilterModal.js

import React from 'react'
import './FilterScreen.css'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
const FilterModal = ({ onClose, onSubmit }) => {
  // Define state for your filter values
  const [gender, setGender] = React.useState('')
  const [age, setAge] = React.useState('')
  const [country, setCountry] = React.useState('')

  const genderOptions = ['Male', 'Female', 'Other']
  const ageOptions = ['Under 18', '18-25', '26-35', '36-45', '46+']
  const countryOptions = ['USA', 'Canada', 'UK', 'Australia', 'Germany']

  // Handle form submission
  const handleSubmit = () => {
    onSubmit({ gender, age, country })
    onClose()
  }
  const closeModal = () => {
    onSubmit({ gender, age, country })

    onClose()
  }

  return (
    // <div className='modal'>
    //   <div className='modal-content'>
    //     <span className='close' onClick={onClose}>
    //       &times;
    //     </span>
    //     <h2>Apply Filters</h2>
    //     <form onSubmit={handleSubmit}>
    //       <label>Gender:</label>
    //       <input
    //         type='text'
    //         value={gender}
    //         onChange={(e) => setGender(e.target.value)}
    //       />
    //       <label>Age:</label>
    //       <input
    //         type='text'
    //         value={age}
    //         onChange={(e) => setAge(e.target.value)}
    //       />
    //       <label>Country:</label>
    //       <input
    //         type='text'
    //         value={country}
    //         onChange={(e) => setCountry(e.target.value)}
    //       />
    //       <button type='submit'>Apply Filters</button>
    //     </form>
    //   </div>
    // </div>
    <div
      className='modal show'
      style={{ display: 'block', position: 'initial' }}
    >
      <Modal.Dialog>
        <Modal.Header closeButton>
          <Modal.Title>Apply Filters</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Finds Friends according to your interests</p>
          <form onSubmit={handleSubmit}>
            <div className='d-flex row justify-content-between'>
              <label>Gender:</label>
              <select
                value={gender}
                onChange={(e) => setGender(e.target.value)}
              >
                <option value=''>Select Gender</option>
                {genderOptions.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <label>Age:</label>
              <select value={age} onChange={(e) => setAge(e.target.value)}>
                <option value=''>Select Age</option>
                {ageOptions.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <label>Country:</label>
              <select
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              >
                <option value=''>Select Country</option>
                {countryOptions.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          </form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant='secondary'>Close</Button>
          <Button variant='primary' onClick={closeModal}>
            Save changes
          </Button>
        </Modal.Footer>
      </Modal.Dialog>
    </div>
  )
}

export default FilterModal
