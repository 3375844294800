import React from 'react';
import './Footer.css';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Link } from "react-router-dom";
import Logo from '../../Images/friendsRightNowLogo.png'
import Instagram from '../../Images/instagram.png';
import Facebook from '../../Images/facebook.png';
import Twitter from '../../Images/twitter.png';

const Footer = () => {
  return (
    <>

      <div class="container-fluid footer mt-4 p-0">
         <div class="container p-0">
            <div class="d-flex justify-content-between my-0">
              <img src={Logo} className='logoFooter'/>
              <div className='logos'>
                <img src={Facebook} className='px-2' />
                <img src={Twitter} className='px-2'/>
                <img src={Instagram} className='px-2'/>
              </div>

               


             

           

            
            </div>
          </div>

          <div class="text-center p-3" style={{ backgroundClip: 'rgba(0, 0, 0, 0.2)' }}>
            {/* <a class="text-white" href="https://mdbootstrap.com/">MDBootstrap.com</a> */}
          </div>

      </div>
    </>
  )

}


export default Footer;