import React from "react";
 
export const SidebarData = [
  {
    title: "Find Friends",
    path: "/findFriends",
    icon: 'friendsIcon.png',
  },
  {
    title: "Chat Home",
    path: "/chatHome",
    icon: 'homeIcon.png',
 
  },
  {
    title: "Profile",
    path: "/profile",
    icon: 'profileIcon.png',
  },
  {
    title: "Chat now",
    path: "/chatting",
    icon: 'messageIcon.png',
 
  },
  {
    title: "My Fav",
    path: "/favourite",
    icon: 'heartIcon.png',
  },
  {
    title: "Settings",
    path: "/settings",
    icon: 'settingsIcon.png',
  },
];