import React, { useState, useEffect } from 'react'
import user from '../../Images/african.png';
import './ChatPage.css';
import Sidebar from '../../components/sidebar/sidebar';
import server from '../../server/server';
import ConversationDetail from './ConversationDetails';
const ChatPage = () => {
    const [profileDetails, setProfileDetails] = useState([]);
    const [primary, setPrimary] = useState(false);
    const [pending, setPending] = useState(false);
    const [requests, setRequests] = useState(false);
    const [showConvo,setShowConvo] = useState(true);

    const clickedPrimary = async () => {
        setPrimary(true);
        setPending(false);
        setRequests(false);
    }


    const clickedPending = async () => {
        setPrimary(false);
        setPending(true);
        setRequests(false);
    }


    const clickedRequests = async () => {
        setPrimary(false);
        setPending(false);
        setRequests(true);
    }
    const conversation = [{
        id: 0,
        image: user,
        name: 'virk'
    },
    {
        id: 1,
        image: user,
        name: 'Sherry'
    },
    {
        id: 2,
        image: user,
        name: 'Hello'
    }

    ]


    const [selectedConversation, setSelectedConversation] = useState(null);

    const handleSelectConversation = (conversationId) => {
        setSelectedConversation(conversationId);
        setShowConvo(true);
    };

    const getUserProfile = async () => {
        const userId = localStorage.getItem('userId');
        const data = {
            userId: userId
        }
        const response = await server.post('user/showFriends', data)
        console.log(response.data.requestsReceived);
        setProfileDetails(response.data)

    }
    const acceptFriendRequest = async(id)=>{
        const userId = localStorage.getItem('userId');
        const data = {
            _id: userId,
            requestId:id
        }
        const response = await server.post('user/acceptFriendRequest',data);
        console.log(response);
        if(response.status === 200){
            alert('request accepted successfully')
        }
        window.location.reload();

    }
    

    useEffect(() => {
        getUserProfile()
    }, []);


    return (
        <>
            <div className='profile-container'>
                <Sidebar />
                <div className='background'>
                    <div className='row d-flex'>
                        <div className='col col-1'></div>
                        <div className='col col-4 mob my-4'>
                            <h5 className='text-black m-4'>
                                Chat Box
                            </h5>
                            <div className='d-flex justify-content-around my-2'>
                                <button className='chatBtn' style={{ backgroundColor: primary ? "black" : "white", color: primary ? "white" : "black" }} onClick={clickedPrimary}>Primary</button>
                                <button className='chatBtn' style={{ backgroundColor: pending ? "black" : "white", color: pending ? "white" : "black" }} onClick={clickedPending}>Pending</button>
                                <button className='chatBtn' style={{ backgroundColor: requests ? "black" : "white", color: requests ? "white" : "black" }} onClick={clickedRequests}>Requests</button>
                            </div>
                            <div className='row d-flex my-4'>
                                {
                                    primary &&
                                    <div className=''>
                                        {profileDetails?.friends?.map((data) => (
                                            <div className='d-flex align-items-center text-center py-2'>
                                              <button className='convoBtn' onClick={() => handleSelectConversation(profileDetails._id)}>
                                                <img src={data?.userId?.profileImage} style={{ width: 60, height: 60, borderRadius: 36 }} alt="Friends"/>
                                                <p className='text-center fw-bold px-4'>{data?.userId?.firstName}</p>
                                                </button>
                                            </div>  
                                        ))}
                                    </div>}
                                {
                                    requests &&
                                    <div className='requests'>
                                        {profileDetails?.friendRequest?.map((data) => (
                                            <div className='d-flex align-items-center text-center py-2'>
                                                <img src={data?.userId?.profileImage} style={{ width: 60, height: 60, borderRadius: 36 }} alt="Friends" />
                                                <p className='text-center fw-bold px-4'>{data?.userId?.firstName}</p>
                                            </div>
                                        ))}
                                    </div>}

                                    {
                                    pending &&
                                    <div className='requests'>
                                        {profileDetails?.requestsReceived?.map((data) => (
                                            <div className='d-flex align-items-center text-center py-2'>
                                                <img src={data?.userId?.profileImage} style={{ width: 60, height: 60, borderRadius: 36 }} alt="Friends" />
                                                <p className='text-center fw-bold px-4'>{data?.userId?.firstName}</p>
                                                <button className='requestbuttons' onClick={()=>{acceptFriendRequest(data.userId._id)}}>Accept</button>
                                                <button className='requestbuttons'>Reject</button>
                                            </div>
                                        ))}
                                    </div>}

                            </div>
                        </div>
                        <div className='col col-6 my-4'>
                     {
                     showConvo &&
                     <div className='message__container'>
        <ConversationDetail conversationId={selectedConversation}/>
          
        </div>}
                        </div>
                    </div>


                </div>

            </div>


        </>
    )
}



export default ChatPage;