import React, { useState, useEffect } from "react";
import Dp from "../../Images/girlProfile.png";
import server from "../../server/server";
import { auth, storage } from "../../components/firebase/firebase";
import { useParams } from "react-router-dom";
import "./FriendProfile.css";
import Heart from "../../Images/heart.png";
import Sidebar from "../../components/sidebar/sidebar";
const profileDetails = {
  display: Dp,
  name: "Christina",
  phoneNumber: "+123456789",
  email: "christina@gmail.com",
  password: "123456",
  age: "26",
  country: "USA",
  language: "English",
  gender: "Female",
};

const FriendProfile = () => {
  const { userId } = useParams();
  const [profileDetails, setProfileDetails] = useState({});
  const [url, setURL] = useState("");
  const [selectedFiles, setFiles] = useState("");
  const [friends, setFriends] = useState(false);

  const [file, setFile] = useState("");
  const [Title, setTitle] = useState("");
  const [Price, setPrice] = useState("");
  const [Description, setDescription] = useState("");
  const showFile = (event) => {
    let file = event.target.files[0];
    setFiles(file);
  };
  const getUserProfile = async () => {
    const myId = localStorage.getItem("userId");
    const data = {
      _id: userId,
    };
    const response = await server.post("/user/getUserById", data);
    console.log(response.data);
    setProfileDetails(response.data);
    const foundFriend = response.data.friends.find(
      (friend) => friend.userId === myId
    );
    if (foundFriend) {
      setFriends(true);
    }
  };
  useEffect(() => {
    getUserProfile();
    console.log(userId);
  }, []);

  const addToFav = async () => {
    const id = localStorage.getItem("userId");
    const data = {
      userId: userId,
      _id: id,
    };
    const response = await server.post("user/addToFav", data);
    if (response.status === 200) {
      alert("User added to favourites");
    }
    if (response.status === 201) {
      alert("User already in your Favourites");
    }
    console.log(response);
  };

  const sendFriendRequest = async () => {
    const _id = localStorage.getItem("userId");
    const data = {
      userId: userId,
      _id: _id,
    };
    const response = await server.post("/user/sendFriendRequest", data);
    console.log(response);
    if (response.status === 200) {
      alert("friend request sent");
    }
    if (response.status === 201) {
      alert("Request pending or Already a Friend");
    }
  };

  const onSubmit = async () => {
    auth.signInAnonymously().then(() => {
      // Signed in..
      // const data = values
      const uploadTask = storage
        .ref(`/images/${selectedFiles.name}`)
        .put(selectedFiles);
      uploadTask.on("state_changed", console.log, console.error, () => {
        storage
          .ref("images")
          .child(selectedFiles.name)
          .getDownloadURL()
          .then((url) => {
            setFiles(null);
            setURL(url);
            alert(url);

            // Backend(values, url);
            // console.log(data);
            // const data = new FormData();

            // for (var propName in values) {
            //   //to remove any empty field
            //   if (values[propName] === "") {
            //     delete values[propName];
            //   }
            // }
            // values.append("obj", JSON.stringify({ ...values, image: url }));
            // const img =  FileResize(selectedFiles);
            // data.append("image", img);
            // console.log("aur bhaui")

            // values['imageUrl']=url,

            // alert(JSON.stringify(values))

            // console.log(values);
            // val['imageUrl'] = url
            // register(url);

            // dispatch(createProduct(values, url, setLoading));

            // resetForm({
            //   values: "",
            // });
            // history.push("/productList");
            setFiles(null);
          });
      });
    });
  };

  return (
    <>
      <div className="profile-container">
        <Sidebar />
        <div className="background">
          <div className="container">
            <div className="borderBottom">
              <div className="d-flex justify-content-between">
                <h1 className="text-black fw-light p-4">Profile</h1>
                <div className="m-4 px-4">
                  <button className="heartBtn" onClick={addToFav}>
                    <img
                      src={Heart}
                      style={{
                        width: 60,
                        height: 60,
                        alignSelf: "center",
                        padding: 5,
                        marginInline: 15,
                      }}
                      alt="Friend"
                    />
                  </button>
                  {friends && (
                    <button className="connectBtn" onClick={sendFriendRequest}>
                      Remove Friend
                    </button>
                  )}
                  {!friends && (
                    <button className="connectBtn" onClick={sendFriendRequest}>
                      Connect to chat
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="profileDetails my-4">
              <div className="row d-flex">
                <div className="col col-2">
                  <img
                    src={profileDetails.profileImage}
                    className="profileImage"
                    alt="Friend"
                  />
                </div>
                <div className="col col-2"></div>
                <div className="col col-2 justify-content-between my-4 py-4 ">
                  <h6 className="text-black fw-bold">Full Name</h6>
                  <p className="">{profileDetails.firstName}</p>
                  <h6 className="text-black fw-bold mt-4 py-4">Age</h6>
                  <p className="">{profileDetails.age}</p>
                </div>
                <div className="col col-2 justify-content-between my-4 py-4">
                  <h6 className="text-black fw-bold">Phone Number</h6>
                  <p className="">{profileDetails.phoneNumber}</p>
                  <h6 className="text-black fw-bold mt-4 py-4">Country</h6>
                  <p className="">{profileDetails.country}</p>
                </div>
                <div className="col col-2 justify-content-between my-4 py-4">
                  {/* <h6 className='text-black fw-bold'>
                                    Email
                                </h6>
                                <p className=''>{profileDetails.email}</p> */}
                  <h6 className="text-black fw-bold mt-4 py-4">Language</h6>
                  <p className="">{profileDetails.language}</p>
                </div>
                <div className="col col-2 justify-content-between my-4 py-4">
                  {/* <h6 className='text-black fw-bold'>
                                    Password
                                </h6>
                                <p className=''>{profileDetails.password}</p> */}
                  <h6 className="text-black fw-bold mt-4 py-4">Gender</h6>
                  <p className="">{profileDetails.gender}</p>
                </div>
              </div>
              <div className="row d-flex justify-content-around">
                <div className="col col-3 profileTexts">
                  <h6>Bio Description</h6>
                  <p className="text-black">{profileDetails.bio}</p>
                </div>
                <div className="col col-3 profileTexts">
                  <h6>Why I will be a good friend</h6>
                  <p className="text-black">{profileDetails.goodFriend}</p>
                </div>
                <div className="col col-3 profileTexts">
                  <h6>What I look for in a friend</h6>
                  <p className="text-black">{profileDetails.lookFor}</p>
                </div>
              </div>
              <h6 className="mt-4 mx-4">Uploaded Photos</h6>
              {profileDetails?.pictures?.map((data) => (
                <img src={data} style={{ width: 200, height: 200 }} />
              ))}
              <div className="row d-flex">
                {/* <input
                                type="file"
                                name="file"
                                onChange={(e) => showFile(e)}
                                id="file"
                                className="bl-inputfile"
                            /> */}
              </div>
              <h4 className="text-black m-4">My Interests</h4>
              <div className="d-flex">
                {profileDetails.interests?.map((data) => (
                  <p className="interestsBg">{data}</p>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FriendProfile;
