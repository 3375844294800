import react from 'react';
import Sidebar from '../../components/sidebar/sidebar';

import './SettingsScreen.css';
import { Link } from 'react-router-dom';


const SettingsScreen = () => {
    return (
        <>
            <div className='profile-container'>
                <Sidebar />
                <div className='background'>
                    <div className='container'>

                        <div className='borderBottom'>
                            <h1 className='text-black fw-light p-4'>
                                General Settings
                            </h1>
                        </div>
                        <div className='bg-white'>
                            <div className='d-flex justify-content-between'>
                                <div className='grayBg'>
                                    <Link to='/editProfile' style={{textDecoration:'none'}}>
                                    <h5 className='text-black'>
                                        Account Info
                                    </h5>
                                    <p className='text-black fw-light'>
                                        Edit your existing account information
                                    </p>
                                    </Link>
                                </div>
                                <div className='grayBg'>
                                    <h5 className='text-black'>
                                        Settings
                                    </h5>
                                    <p className='text-black fw-light'>
                                        Edit your existing account information
                                    </p>
                                </div>


                            </div>
                            <div className='d-flex justify-content-between'>
                                <div className='grayBg'>
                                    <h5 className='text-black'>
                                        Go Private
                                    </h5>
                                    <p className='text-black fw-light'>
                                        Want to stay hidden? Go private and no one will be able to see your profile
                                    </p>
                                </div>
                                <div className='grayBg'>
                                    <h5 className='text-black'>
                                        Settings
                                    </h5>
                                    <p className='text-black fw-light'>
                                        Edit your existing account information
                                    </p>
                                </div>


                            </div>
                            <div className='d-flex justify-content-between'>
                                <div className='grayBg'>
                                    <h5 className='text-black'>
                                        Account Info
                                    </h5>
                                    <p className='text-black fw-light'>
                                        Edit your existing account information
                                    </p>
                                </div>
                                <div className='grayBg'>
                                    <h5 className='text-black'>
                                        Settings
                                    </h5>
                                    <p className='text-black fw-light'>
                                        Edit your existing account information
                                    </p>
                                </div>


                            </div>
                        </div>

                    </div>

                </div>
            </div>

        </>
    )
}



export default SettingsScreen;