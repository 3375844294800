
// import firebase from 'firebase/compat/app';
import { getStorage } from 'firebase/storage'
import { initializeApp } from "firebase/app";
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import 'firebase/compat/auth'

import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDVallSM6yGVKEpMSQglIFjZXUMCFLdupY",
  authDomain: "friendsrightnow-50bb8.firebaseapp.com",
  projectId: "friendsrightnow-50bb8",
  storageBucket: "friendsrightnow-50bb8.appspot.com",
  messagingSenderId: "19667153018",
  appId: "1:19667153018:web:095cd6cfc05e6ec2344bc3",
  measurementId: "G-MKNKTCXKZ2"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const storage = firebase.storage();
// const auth = fire(app);
const auth = firebase.auth(app);
export { auth, storage, auth as default }
