import React,{useState,useEffect} from 'react';
import './ProfileScreen.css';
import Dp from '../../Images/girlProfile.png';
import server from '../../server/server';
import {auth,storage} from '../../components/firebase/firebase'
import Sidebar from '../../components/sidebar/sidebar';
const profileDetails = {
    display: Dp,
    name: 'Christina',
    phoneNumber: '+123456789',
    email: 'christina@gmail.com',
    password: '123456',
    age: '26',
    country: 'USA',
    language: 'English',
    gender: 'Female'
}

const ProfileScreen = () => {
const [profileDetails,setProfileDetails] = useState({});
const [url, setURL] = useState("");
const[selectedFiles,setFiles] = useState('');







    const [file, setFile] = useState("");
    const [Title, setTitle] = useState("");
    const [Price, setPrice] = useState("");
    const [Description, setDescription] = useState("");
    const showFile = (event) => {
        let file = event.target.files[0];
        setFiles(file);
        onSubmit();
    };
const getUserProfile = async()=>{
    const userId = localStorage.getItem('userId');
    const data={
        _id:userId
    }
    const response = await server.post('user/getUserById',data)
    console.log(response.data);
    setProfileDetails(response.data)

}


    const addImage = async(url)=>{
        const id = localStorage.getItem('userId');
        const data={
            userId:id,
            imageUrl:url
        }

        const response = await server.post('/user/addImage',data);
        console.log(response);
        alert('Added successfully');
        window.location.reload();
    }



    

useEffect(()=>{
    getUserProfile();

},[])



const onSubmit = async () => {
    auth.signInAnonymously()
        .then(() => {
            // Signed in..
            // const data = values
            const uploadTask = storage.ref(`/images/${selectedFiles.name}`).put(selectedFiles);
            uploadTask.on("state_changed", console.log, console.error, () => {
                storage
                    .ref("images")
                    .child(selectedFiles.name)
                    .getDownloadURL()
                    .then((url) => {
                        setFiles(null);
                        setURL(url);
                        alert(url);

                        // Backend(values, url);
                        // console.log(data);
                        // const data = new FormData();



                        // for (var propName in values) {
                        //   //to remove any empty field
                        //   if (values[propName] === "") {
                        //     delete values[propName];
                        //   }
                        // }
                        // values.append("obj", JSON.stringify({ ...values, image: url }));
                        // const img =  FileResize(selectedFiles);
                        // data.append("image", img);
                        // console.log("aur bhaui")

                        // values['imageUrl']=url,

                        // alert(JSON.stringify(values))

                      
                        // console.log(values);
                        // val['imageUrl'] = url
                        addImage(url);

                        // dispatch(createProduct(values, url, setLoading));

                        // resetForm({
                        //   values: "",
                        // });
                        // history.push("/productList");
                        setFiles(null);
                    })
            })


        })
}

    return (
        <>
        <div className='profile-container'>
            <Sidebar/>
            <div className='background'>
                <div className='container'>

                    <div className='borderBottom'>
                        <h1 className='text-black fw-light p-4'>
                            My Profile
                        </h1>
                    </div>
                    <div className='profileDetails my-4'>
                        <div className='row d-flex'>
                            <div className='col col-2'>
                                <img src={profileDetails?.profileImage} className='profileImage' />
                            </div>
                            <div className='col col-1'></div>
                            <div className='col col-2 justify-content-between my-4 py-4 '>
                                <h6 className='text-black fw-bold'>
                                    Full Name
                                </h6>
                                <p className=''>{profileDetails?.firstName}</p>
                                <h6 className='text-black fw-bold mt-4 py-4'>
                                    Age
                                </h6>
                                <p className=''>{profileDetails?.age}</p>
                            </div>
                            <div className='col col-2 justify-content-between my-4 py-4'>
                                <h6 className='text-black fw-bold'>
                                    Phone Number
                                </h6>
                                <p className=''>{profileDetails?.phoneNumber}</p>
                                <h6 className='text-black fw-bold mt-4 py-4'>
                                    Country
                                </h6>
                                <p className=''>{profileDetails?.country}</p>
                            </div>
                            <div className='col col-3 justify-content-between my-4 py-4'>
                                <h6 className='text-black fw-bold'>
                                    Email
                                </h6>
                                <p className=''>{profileDetails?.email}</p>
                                <h6 className='text-black fw-bold mt-4 py-4'>
                                    Language
                                </h6>
                                <p className=''>{profileDetails?.language}</p>
                            </div>
                            <div className='col col-2 justify-content-between my-4 py-4'>
                                {/* <h6 className='text-black fw-bold'>
                                    Password
                                </h6> */}
                                {/* <p className=''>{profileDetails?.password}</p> */}
                                <h6 className='text-black fw-bold mt-4 py-4'>
                                    Gender
                                </h6>
                                <p className=''>{profileDetails?.gender}</p>
                            </div>
                        </div>
                        <div className='row d-flex justify-content-around'>
                            <div className='col col-3 profileTexts'>
                                <h6 className='py-2'>
                                    Bio Description
                                </h6>
                                <p className='text-black'>
                                {profileDetails?.bio}
                                </p>
                            </div>
                            <div className='col col-3 profileTexts'>
                                <h6 className='py-2'>
                                    Why I will be a good friend
                                </h6>
                                <p className='text-black'>
                                    {profileDetails?.goodFriend}
                                </p>
                            </div>
                            <div className='col col-3 profileTexts'>
                                <h6 className='py-2'>
                                    What I look for in a friend
                                </h6>
                                <p className='text-black'>
                                {profileDetails?.lookFor}
                                </p>
                            </div>
                        </div>
                        <h6 className='mt-4 mx-4'>
                            Uploaded Photos
                        </h6>
                        {/* <img src={profileDetails.pictures[0]}/> */}
                        {profileDetails?.pictures?.map((data)=>(
                                <img src={data} style={{width:120,height:120,borderRadius:80,margin:10}}/>
                            ))}
                        <div className='row d-flex'>
                            
                        <input
                                type="file"
                                name="file"
                                onChange={(e) => showFile(e)}
                                id="file"
                                className="bl-inputfile"
                            />
                        
                        </div>
                        <h4 className='text-black m-4'>
                                My Interests
                            </h4>
                        <div className='d-flex'>
                            
                            {profileDetails?.interests?.map((data)=>(
                                <p className='interestsBg'>{data}</p>
                            ))}
                        </div>
                    </div>
                </div>

            </div>
            </div>
        </>
    )
}

export default ProfileScreen;