import React, { useState } from 'react';
import './RegisterationDetails.css';
import Dp from '../../Images/girlProfile.png';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import server from '../../server/server';
const profileDetails = {
    display: Dp,
    name: 'Christina',
    phoneNumber: '+123456789',
    email: 'christina@gmail.com',
    password: '123456',
    age: '26',
    country: 'USA',
    language: 'English',
    gender: 'Female'
}

const RegisterDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [bio, setBio] = useState('');
    const [lookFor, setLookFor] = useState('');
    const [goodFriend, setGoodFriend] = useState('');

    const registerNow = async () => {
        navigate('/interests', {
            state: {
                firstName: location.state.firstName, lastName: location.state.lastName, email: location.state.email, password: location.state.password, age: location.state.age, gender: location.state.gender, language: location.state.language, country: location.state.country, phoneNumber: location.state.phoneNumber, imageUrl: location.state.imageUrl, lookFor: lookFor,
                goodFriend: goodFriend,
                bio: bio,
            }
        });
        // const data = {
        //     firstName: location.state.firstName,
        //     lastName: location.state.lastName,
        //     email: location.state.email,
        //     password: location.state.password,
        //     phoneNumber: location.state.phoneNumber,
        //     gender: location.state.gender,
        //     language: location.state.language,
        //     age: location.state.age,
        //     country: location.state.country,
        //     lookFor: lookFor,
        //     goodFriend: goodFriend,
        //     bio: bio,
        //     imageUrl: location.state.imageUrl
        // }
        // const response = await server.post('/user/signup', data);
        // console.log(response);
    }


    return (
        <>
            <div className='background'>
                <div className='container'>

                    <div className='borderBottom'>
                        <h1 className='text-black fw-light p-4'>
                            Register Now
                        </h1>
                    </div>
                    <div className='profileDetails my-4'>
                        <h6 className='text-black m-4 p-4'>
                            Bio Description
                        </h6>
                        <input type='description' className='textInput' onChange={(e) => { setBio(e.target.value) }} />

                        <h6 className='text-black m-4 p-4'>
                            Why I will be a good friend
                        </h6>
                        <input type='description' className='textInput' onChange={(e) => { setGoodFriend(e.target.value) }} />
                        <h6 className='text-black m-4 p-4'>
                            What I look for in a friend
                        </h6>
                        <input type='description' className='textInput' onChange={(e) => { setLookFor(e.target.value) }} />
                        <button className='nextButton m-4' onClick={registerNow}>Next</button>

                    </div>
                </div>

            </div>
        </>
    )
}

export default RegisterDetails;