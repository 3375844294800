import logo from './logo.svg';
import './App.css';
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes, Switch, Redirect } from "react-router-dom";
import HomeScreen from './screens/HomeScreen/HomeScreen';
import 'bootstrap/dist/css/bootstrap.css';
import AboutUs from './screens/AboutUs/AboutUs';
import HowItWorksScreen from './screens/HowItWorks/HowItWorksScreen';
import ContactUsScreen from './screens/ContactUs/ContactUsScreen';
import LoginScreen from './screens/LoginScreen/LoginScreen';
import SignupScreen from './screens/SignupScreen/SignupScreen';
import UserDashboard from './screens/UserDashboard/UserDashboard';
import Dashboard from './screens/Dashboard/Dashboard';
import BlogScreen from './screens/BlogScreen/BlogScreen';
import ForGarageScreen from './screens/ForGarageScreen/ForGarageScreen';
import GetQuoteScreen from './screens/GetQuoteScreen/GetQuoteScreen';
import Garages from './screens/Garages/Garages';
import ViewGarage from './screens/ViewGarage/ViewGarage';
import SettingsScreen from './screens/SettingsScreen/SettingsScreen';


// Put any other imports below so that CSS from your
// components takes precedence over default styles.
import Sidebar from './components/sidebar/sidebar';
import Stripe from './screens/Stripe/Stripe';
import Register from './screens/Registeration/RegisterationScreen';
import ProfileScreen from './screens/ProfileScreen/ProfileScreen';
import RegisterDetails from './screens/RegisterationDetails/RegisterationDetails';
import Favourite from './screens/Favourite/Favourite';
import FindFriends from './screens/FindFriends/FindFriends';
import ChatPage from './screens/ChatPage/ChatPage';
import Interests from './screens/Interests/InterestsScreen';
import FriendProfile from './screens/FriendProfile/FriendProfile';
import ChatScreen from './screens/RandomChat/ChatPage';
import EditProfile from './screens/EditProfile/EditProfile';



import socketIO from "socket.io-client"
import UserName from './screens/RandomChat/Username';

// const socket = socketIO.connect("https://friendsrightnowserver-d476388ffa5e.herokuapp.com/")

const socket = socketIO.connect(" https://cfdb-154-57-223-182.ngrok-free.app");
// const socket = socketIO.connect('http://localhost:4000')


function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path='/sidebar' element={<Sidebar />} />
        <Route exact path="/" element={<HomeScreen />} />
        <Route path="/login" element={<LoginScreen />} />
        <Route path="/signup" element={<SignupScreen />} />
        {/* <Route path='/stripe' element={<Stripe/>}/> */}
        <Route path='/register' element={<Register />} />
        <Route path='/profile' element={<ProfileScreen />} /> 
        <Route path='/registerDetails' element={<RegisterDetails />} />
        <Route path='/favourite' element={<Favourite />} />
        <Route path='/findFriends' element={<FindFriends />} />
        <Route path='/chatHome' element={<ChatPage />} />
        <Route path='/interests' element={<Interests />} />
        <Route path='/user/:userId' element={<FriendProfile />} />
        <Route path='/chatting' element={<UserName socket={socket} />} />
        <Route path="/chat" element={<ChatScreen socket={socket} />} />
        <Route path="/settings" element={<SettingsScreen/>} />
        <Route path="/editProfile" element={<EditProfile/>} />
      </Routes>
    </BrowserRouter>
  );
}
export default App;
