import React, { useState } from 'react'
import './LoginScreen.css'
import NavigationBar from '../../components/navbar/navbar'
import { Link } from 'react-router-dom'
// import server from '../../components/server/server';
import server from '../../server/server'
import { useNavigate } from 'react-router-dom'
import GoogleIcon from '@mui/icons-material/Google'
import FacebookIcon from '@mui/icons-material/Facebook'
import EmailIcon from '@mui/icons-material/Email'
import LockIcon from '@mui/icons-material/Lock'
import PersonIcon from '@mui/icons-material/Person'
import Logo from '../../Images/image.png'

const LoginScreen = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()

  const login = async () => {
    const data = {
      email: email,
      password: password,
    }
    // const _data = JSON.parse(data)
    const response = await server.post('/user/login', data)
    console.log(response)
    if (response.status === 200) {
      localStorage.setItem('userId', response.data._id)

      navigate('/profile')
    }
    // navigate('/profile');
  }

  return (
    <>
      <div className='container-fluid loginBackground'>
        <div className='row'>
          <div className='col col-6'></div>
          <div className='col col-6 blur py-4'>
            <Link to='/'>
              <img src={Logo} style={{ width: 200 }} />
            </Link>
            <p className='text-white my-2 py-2'>User Login</p>
            <form>
              <div class='form-group'>
                <label for='exampleInputEmail1' className='text-white fw-light'>
                  UserName/Email
                </label>
                <input
                  type='email'
                  class='form-control'
                  id='exampleInputEmail1'
                  aria-describedby='emailHelp'
                  placeholder='John Doe'
                  onChange={(e) => {
                    setEmail(e.target.value)
                  }}
                />
              </div>

              <div class='form-group my-4'>
                <label for='exampleInputEmail1' className='text-white fw-light'>
                  Password
                </label>
                <input
                  type='password'
                  class='form-control'
                  id='exampleInputPassword'
                  aria-describedby='emailHelp'
                  placeholder='Password'
                  onChange={(e) => {
                    setPassword(e.target.value)
                  }}
                />
              </div>
              <div className='form-group m-4'>
                <button type='button' className='loginbutton' onClick={login}>
                  {' '}
                  Login Now
                </button>
              </div>
            </form>
            <p className='text-center text-white fw-light'>
              Don't have an account?{' '}
              <button
                className='border-0 signupbtn'
                onClick={() => {
                  navigate('/signup')
                }}
              >
                {' '}
                Signup{' '}
              </button>
            </p>
          </div>
        </div>
      </div>

      {/* <section className="pt-5 pb-5 mt-0 align-items-center d-flex bg-dark" style={{ minHeight: "100vh", backgroundSize: 'cover', backgroundImage: 'url(https://images.unsplash.com/photo-1477346611705-65d1883cee1e?ixlib=rb-0.3.5&amp;q=80&amp;fm=jpg&amp;crop=entropy&amp;cs=tinysrgb&amp;w=1920&amp;fit=max&amp;ixid=eyJhcHBfaWQiOjMyMDc0fQ&amp;s=c0d43804e2c7c93143fe8ff65398c8e9)' }}>
          <div className="container-fluid">
            <div className="row  justify-content-center align-items-center d-flex-row text-center h-100">
              <div className="col-12 col-md-4 col-lg-3 h-50 ">
                <div className="card shadow">
                  <div className="card-body mx-auto">
                    <h4 className="card-title mt-3 text-center">Login</h4>
                    <p className="text-center">Use your credentials to Login</p>
                    <p>
                    
                    </p>

                    <form>
                      <div class="form-group">
                        <label for="exampleInputEmail1">UserName</label>
                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Username" />
                        <small id="emailHelp" class="form-text text-muted">We need your name to display in your Profile</small>
                      </div>
                      <div class="form-group">
                        <label for="exampleInputEmail1">Email address</label>
                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email" />
                        <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
                      </div>
                      <div class="form-group">
                        <label for="exampleInputEmail1">Password</label>
                        <input type="password" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Password" />
                        <small id="emailHelp" class="form-text text-muted">Create a strong password to stay secure</small>
                      </div>
                      <div className="form-group m-4">
                        <button type="submit" className="btn btn-secondary btn-block px-4" onClick={()=>{login()}}> Login</button>
                      </div>
                      <p className="text-center">Want to register?
                        <a href="" className='mx-2'>SignUp</a>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
    </>
  )
}

export default LoginScreen
