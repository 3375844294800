import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import './InterestScreen.css';
import server from '../../server/server';
const Interests = () => {
    const navigate = useNavigate();
    const location = useLocation();


    const [selectedItems, setSelectedItems] = useState([]);

    const items = ['Football', 'Cricket', 'Technology', 'Science', 'Social Networking', 'Amazon', 'Netflix', 'Hockey'];

    const handleItemClick = (item) => {
        // Check if the item is already selected
        if (selectedItems.includes(item)) {
            // If selected, remove it from the list
            setSelectedItems(selectedItems.filter((selectedItem) => selectedItem !== item));
        } else {
            // If not selected, add it to the list
            setSelectedItems([...selectedItems, item]);
        }
    };
    const handleClick = async () => {
        const data = {
            firstName: location.state.firstName,
            lastName: location.state.lastName,
            email: location.state.email,
            password: location.state.password,
            phoneNumber: location.state.phoneNumber,
            gender: location.state.gender,
            language: location.state.language,
            age: location.state.age,
            country: location.state.country,
            lookFor: location.state.lookFor,
            goodFriend: location.state.goodFriend,
            bio: location.state.bio,
            imageUrl: location.state.imageUrl,
            interests: selectedItems
        }
        const response = await server.post('/user/signup', data);
        if(response.status === 200){
            alert('Signup Successfull')
            navigate('/login')
        }
        console.log(response);
    };



    return (
        <>
            <div className='background'>
                <div className='container'>

                    <div className='borderBottom'>
                        <h1 className='text-black fw-light p-4'>
                            Register Now
                        </h1>
                    </div>

                    <div className='bg-white'>
                        <div className='row d-flex justify-content-around py-4 m-2'>
                            {items.map((data, index) => (
                                <button
                                    className='interestBtn1 w-15 m-4'
                                    key={index}
                                    onClick={() => handleItemClick(data)}
                                    style={{ cursor: 'pointer', fontWeight: selectedItems.includes(data) ? 'bold' : 'normal' }}
                                >
                                    {data}
                                </button>
                            ))}

                            {/* <button className='interestBtn w-15'>Football</button>
                            <button className='interestBtn1 w-15'>Netflix</button>
                            <button className='interestBtn2 w-15'>Movie</button>
                            <button className='interestBtn3 w-15'>Cricket</button> */}
                        </div>
                        <button className='completeBtn' onClick={handleClick}>Next</button>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Interests;