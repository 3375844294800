// ConversationDetail.js

import React, { useState, useEffect } from "react";
import axios from "axios";
import server from "../../components/server/server";

const ConversationDetail = ({ conversationId }) => {
  const [conversation, setConversation] = useState();
  const [newMessage, setNewMessage] = useState('');

  const getConversation = async () => {
    const data = {
      userId: conversationId,
    };
    const response = await server.post("chat/getMessages", data);
    console.log(response.data);
    if (response.status === 200) {
      setConversation(response.data);
    }
  };

  useEffect(() => {
    // console.log(conversationId)
    // if (conversationId) {
    //     axios.get(`/conversations/${conversationId}`)
    //         .then(response => {
    //             setConversation(response.data);
    //         })
    //         .catch(error => {
    //             console.error('Error fetching conversation:', error);
    //         });
    // }
    getConversation()
  },[]);

  const handleSendMessage = async() => {
    const userId = localStorage.getItem('userId');

    const data = {
        senderId:userId,
        receiverId:conversationId,
        message:newMessage
    }
    const response = await server
      .post(`/chat/sendMessage`,data);

    if(response.status===200){
        alert('message sent');
    }
    
    }

  return (
    <div>
      {conversationId ? (
        <div>
          <h2>{conversation?.title}</h2>
          <ul>
            {conversation?.messages?.map((message, index) => (
              <li key={index}>
                <strong>{message?.sender}: </strong>
                {message?.text}
              </li>
            ))}
          </ul>
            <input
              type="text"
              value={newMessage}
              onChange={(e) => {setNewMessage(e.target.value)}}
            />
            <button onClick={handleSendMessage}>Send</button>
          <div>
          </div>
        </div>
      ) : (
        <p>Select a conversation from the list</p>
      )}
    </div>
  );
};

export default ConversationDetail;
