import { useEffect, useState } from 'react'
import AliceCarousel from 'react-alice-carousel'
import UserImage from '../../Images/african.png'
import Student from '../../Images/student.png'
import { Link } from 'react-router-dom'
import 'react-alice-carousel/lib/alice-carousel.css'
import './FindFriends.css'
import { useNavigate } from 'react-router-dom'
import server from '../../server/server'
import Sidebar from '../../components/sidebar/sidebar'

// MainComponent.js

import FilterModal from '../FilterScreen/FilterScreen'

const Users = [
  {
    id: 0,
    image: UserImage,
    bio: 'Your potential friend took time to create a profile on this website and is not a random stranger. Your potential friend explained on their profile why they will be a good friend and what they have to offer as a friend.',
  },
  {
    id: 1,
    image: Student,
    bio: 'Your potential friend took time to create a profile on this website and is not a random stranger. Your potential friend explained on their profile why they will be a good friend and what they have to offer as a friend.',
  },
  {
    id: 2,
    image: UserImage,
    bio: 'Your potential friend took time to create a profile on this website and is not a random stranger. Your potential friend explained on their profile why they will be a good friend and what they have to offer as a friend.',
  },
  {
    id: 3,
    image: UserImage,
    bio: 'Your potential friend took time to create a profile on this website and is not a random stranger. Your potential friend explained on their profile why they will be a good friend and what they have to offer as a friend.',
  },
]

const FindFriends = () => {
  const [allUsers, setAllUsers] = useState([])
  const navigate = useNavigate()

  const [showModal, setShowModal] = useState(false)

  // Function to toggle modal visibility
  const toggleModal = () => {
    console.log('pressed')
    setShowModal(!showModal)
  }

  // Function to handle filter submission
  const handleFilterSubmit = (filters) => {
    // Apply filters logic here
    console.log(filters)
  }

  const sendRequest = async (id) => {
    alert(id)
  }

  const showProfile = (data) => {
    navigate('/friendProfile', {
      state: {
        userDetails: data,
      },
    })
  }

  const handleDragStart = (e) => e.preventDefault()
  const items = [
    <div className='imageAndText'>
      <img
        className='galleryImages'
        src={UserImage}
        onDragStart={handleDragStart}
      />
      <p className='garageName text-center'>
        Your potential friend took time to create a profile on this website and
        is not a random stranger. Your potential friend explained on their
        profile why they will be a good friend and what they have to offer as a
        friend.
      </p>
    </div>,
    <div className='imageAndText'>
      <img
        className='galleryImages'
        src={UserImage}
        onDragStart={handleDragStart}
      />
      <p className='text-center garageName'>
        Your potential friend took time to create a profile on this website and
        is not a random stranger. Your potential friend explained on their
        profile why they will be a good friend and what they have to offer as a
        friend.
      </p>
    </div>,
    <div className='imageAndText'>
      <img
        className='galleryImages'
        src={UserImage}
        onDragStart={handleDragStart}
      />
      <p className='garageName text-center'>
        Your potential friend took time to create a profile on this website and
        is not a random stranger. Your potential friend explained on their
        profile why they will be a good friend and what they have to offer as a
        friend.
      </p>
    </div>,
  ]

  const showAllUsers = async () => {
    const _id = localStorage.getItem('userId')
    const data = {
      userId: _id,
    }
    const response = await server.post('/user/showAllUsers', data)
    setAllUsers(response.data)
    console.log(response.data)
  }

  useEffect(() => {
    showAllUsers()
  }, [])

  const sendFriendRequest = async (userId) => {
    const _id = localStorage.getItem('userId')
    const data = {
      userId: userId,
      _id: _id,
    }
    const response = await server.post('/user/sendFriendRequest', data)
    console.log(response)
    if (response.status === 200) {
      alert('friend request sent')
    }
  }

  return (
    <>
      <div className='profile-container'>
        <Sidebar />
        <div className='background'>
          <div className='container'>
            <div className='borderBottom'>
              <h1 className='text-black fw-light p-4'>Find Friends</h1>
              <div>
                <button className='connectBtn1' onClick={toggleModal}>
                  Filter
                </button>
                {showModal && (
                  <FilterModal
                    onClose={toggleModal}
                    onSubmit={handleFilterSubmit}
                  />
                )}
                {/* Your other content */}
              </div>
            </div>
            <div className='imageAndText'>
              <AliceCarousel
                autoHeight
                autoWidth
                mouseTracking
                renderPrevButton={() => {
                  return (
                    <button className='previousBtn p-4 absolute left-0 top-0'>
                      Previous
                    </button>
                  )
                }}
                renderNextButton={() => {
                  return (
                    <button className='nextBtn p-4 absolute right-0 top-0'>
                      Next
                    </button>
                  )
                }}
              >
                {allUsers.map((data) => (
                  <div className='mt-4 py-4'>
                    <Link
                      to={`/user/${data._id}`}
                      style={{ textDecoration: 'none' }}
                    >
                      <img
                        className='galleryImages'
                        src={data.profileImage}
                        onDragStart={handleDragStart}
                        alt='Friends'
                      />
                    </Link>
                    <p className='text-black text-center w-50'>
                      {data.firstName} {data.lastName}
                    </p>
                    <p className='text-black text-center w-50'>{data.bio}</p>
                  </div>
                ))}
              </AliceCarousel>
            </div>
            <div className='row d-flex'>
              <button className='connectBtn1'>Connect to Chat</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FindFriends
